@import '../../utils/variables';

.gt .skiptranslate {
	display: none !important;
}

body.gt {
	top: 0 !important;
}

.goog-te-spinner-pos {
	display: none;
}

#navbar-right {
	@media (min-width: 1200px) {
		margin-left: 3rem;
		padding-top: 1rem;
	}
}

#google-translate {
	.dropdown {
		@media (max-width: 1199px) {
			display: flex;
		}

		&.show {
			.dropdown-toggle {
				&:after {

					animation-name: arrowMove;
					animation-duration: .5s;

					@media (max-width: 1199px) {
						transform: rotate(90deg);
					}
				}
			}
		}

		.dropdown-toggle {
			@media (min-width: 1200px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: white;
			}

			&:after {
				position: relative;
				left: -2px;
				max-width: .35rem;
				border-top: .35em solid;
				border-right: .35em solid transparent;
				border-bottom: 0;
				border-left: .35em solid transparent;

				@media (min-width: 1200px) {
					bottom: -1.3rem;
					transform: scale(1.05);
				}

				@media (max-width: 1199px) {
					transform: rotate(-90deg);
					top: 2px;
					margin-left: 1rem;
					border-top-color: white;
				}
			}

			&:focus,
			&:hover {
				@media (max-width: 1199px) {
					text-decoration: none;
				}
			}
		}

		img {
			opacity: 1;
			transition: all 0.3s ease;
			width: 2rem;
		}

		&.active,
		&:hover {
			img {
				opacity: .8;
			}
		}

		.dropdown-menu {
			background: none;

			@media (min-width: 1200px) {
				min-width: 100%;
				left: 50%;
				transform: translateX(-50%);
				top: 5.8rem;
				border: none;
			}

			@media (max-width: 1199px) {
				width: auto;
				position: static;
				padding: 0;
				border: 0;
				margin: 0;
				border: none;
			}

			&.show {
				@media (max-width: 1199px) {

					display: flex;
					align-items: center;
				}
			}

			img {
				width: auto;

				@media (max-width: 1199px) {
					max-height: 20px;
					margin-top: .55rem;
				}
			}

			.dropdown-item {
				@media (min-width: 1200px) {
					background: white;
					margin-bottom: .3rem;
					animation-name: dropdownMenuAnimation;
					animation-duration: .8s;
					animation-fill-mode: forwards;
					border: 1px solid white;
					padding: 1.5rem 2.5rem;
					transition: .2s all ease-in-out;
				}

				@media (max-width: 1199px) {
					background: transparent;
					padding: 0;
					text-align: center;
				}

				&::before,
				&::after {
					@media (min-width: 1200px) {
						content: '';
						position: absolute;
						display: block;
						width: 25px;
						height: 15px;
						border-color: $primary;
						transition: .4s all ease-in-out;

					}
				}

				&::after {
					@media (min-width: 1200px) {
						top: 3px;
						left: 4px;
						transform: scale(0);
					}
				}

				&::before {
					@media (min-width: 1200px) {
						bottom: 3px;
						right: 4px;
						transform: scale(0) rotate(180deg);
					}
				}

				&:hover {
					@media (min-width: 1200px) {
						background-color: #eee;
					}

					&::after {
						transform: scale(1);
					}

					&::before {
						transform: scale(1) rotate(180deg);
					}
				}

				&:active {
					@media (min-width: 1200px) {
						background-color: $primary;
						color: white;
					}
				}
			}
		}
	}
}

#navbar.fixed-top {
	#google-translate {
		.dropdown-item {
			border: 1px solid #7A6650;
		}
	}
}
